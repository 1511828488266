import React, { FC } from 'react';
import { Button, Icon, Modal, TransitionablePortal } from 'semantic-ui-react';
import { LgpdContainer } from './styles';
import Cookies from 'js-cookie';

export interface ILgpdModal {
  visible: boolean;
  onClose(): void;
}


const LgpdModal: FC<ILgpdModal> = ({ visible, onClose }: ILgpdModal) => {

  function acceptLGPD() {
    Cookies.set('lgpd', 'true', { path: '', expires: 180 });
    onClose();
  }

  function declineLGPD() {
    Cookies.set('lgpd', 'false', { path: '', expires: 180 });
    onClose();
  }

  return (

    <TransitionablePortal
      open={ visible }
      transition={ { 'animation': 'fly up', 'duration': '500' } }
    >
      <Modal
        dimmer='blurring'
        closeIcon
        closeOnDimmerClick
        open={ true }
        onClose={ () => onClose() }
        size='tiny'
      >
        <Modal.Header >
          <Icon name='attention' color='orange' size='large' />
          Política de privacidade e segurança
        </Modal.Header>

        <Modal.Content scrolling >
          <Modal.Description >
            <LgpdContainer>
              <p> Nós usamos cookies ou tecnologias similares para finalidades técnicas e, com seu consentimento, para outras finalidades conforme especificado na política de cookie.</p>
              <p> <strong>Negar</strong> consentimento pode tornar as funcionalidades relacionadas indisponíveis.</p>
              <p>  Use o botão “Aceitar” para consentir. </p>
              <p> Use o botão  “Recusar” para negar.</p>
            </LgpdContainer>
          </Modal.Description>

        </Modal.Content>

        <Modal.Actions>
          <Modal.Actions>
            <Button
              onClick={ () => declineLGPD() }
              negative >
              Recusar
            </Button>
            <Button
              onClick={ () => acceptLGPD() }
              positive >
              Aceitar
            </Button>
          </Modal.Actions>

        </Modal.Actions>
      </Modal >

    </TransitionablePortal>



  );
}

export default LgpdModal;