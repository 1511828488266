import React, { useEffect, useState } from 'react';

import {
  Image,
  Header,
  Button,
  Container,
  Modal,
  Accordion,
  Icon,
  AccordionTitleProps,
} from 'semantic-ui-react';
import { useMenuContext } from '../../contexts/MenuContext';
import Layout from '../../components/Layout';
import { FiapModule } from '../../components/Benefits/Fiap';
import { GympassModule } from '../../components/Benefits/Gympass';
import { FlashModule } from '../../components/Benefits/Flash';
import { MainContainer, ContainerMainBenefits } from './styles';
import { useSelector } from 'react-redux';
import { UserReducerInitialState } from '../../redux/reducers/User';
import { getFirstName } from '../../helper/getFirstName';

export type TModalImage = {
  open: boolean;
  image?: string;
};


export function Benefits() {

  const { userName } = useSelector(
    (state: { user: UserReducerInitialState }) => state.user
  );
  const { setSelectedMenuItem: setItemMenuSelected } = useMenuContext();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [openModalImage, setOpenModalImage] = useState<TModalImage>({
    open: false,
  } as TModalImage);

  const handleOpenModalImage = (image: string) => {
    setOpenModalImage({
      open: true,
      image: image,
    });
  };

  const handleCloseModalImage = () => {
    setOpenModalImage({
      open: false,
    });
  };

  const handleActiveIndex = (_e: unknown, { index }: AccordionTitleProps) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(Number(newIndex));
  };

  useEffect(() => {
    setItemMenuSelected('beneficios');
  }, []);


  return (
    <Layout>
      <MainContainer>
        <Header as='h1' dividing className='headerTitle' id='initial'>
          { `E aí ${ getFirstName(userName) }, bora conhecer os benefícios da Coopex?` }
        </Header>

        <ContainerMainBenefits>
          <div className='benefits'>
            <Container className='benefitCard'>
              <FiapModule handleOpenModalImage={ handleOpenModalImage } />
            </Container>

            <Container className='benefitCard'>
              <GympassModule handleOpenModalImage={ handleOpenModalImage } />
            </Container>

            <Container className='benefitCard'>
              <FlashModule handleOpenModalImage={ handleOpenModalImage } />
            </Container>
          </div>

          <div className='aside'>
            <Accordion>
              <Header>Perguntas Frequentes</Header>
              <Accordion.Title
                className='accordionAsk'
                active={ activeIndex === 0 }
                index={ 0 }
                onClick={ handleActiveIndex }
              >
                <Icon name='dropdown' />
                Como adquirir o Gympass ?
              </Accordion.Title>
              <Accordion.Content
                active={ activeIndex === 0 }
                className='accordionAnswer'
              >
                <p>Para aderir ao Gympass você deve entrar em contato com o canal de relacionamento fazendo sua solicitação.
                  Após seu vínculo ser efetivado você será comunicado e já poderá realizar seu cadastro e escolher o plano que melhor lhe atende.</p>
              </Accordion.Content>

              <Accordion.Title
                active={ activeIndex === 1 }
                index={ 1 }
                onClick={ handleActiveIndex }
                className='accordionAsk'
              >
                <Icon name='dropdown' />
                Posso cancelar o Gympass a qualquer momento ?
              </Accordion.Title>
              <Accordion.Content
                active={ activeIndex === 1 }
                className='accordionAnswer'
              >
                <p>Sim, o cancelamento pode ser realizado a qualquer momento diretamente pelo site ou APP do Gympass.</p>
              </Accordion.Content>

              <Accordion.Title
                active={ activeIndex === 2 }
                index={ 2 }
                onClick={ handleActiveIndex }
                className='accordionAsk'
              >
                <Icon name='dropdown' />
                Os meu familiares possuem o mesmo desconto na FIAP ?
              </Accordion.Title>
              <Accordion.Content
                active={ activeIndex === 2 }
                className='accordionAnswer'
              >
                <p>Sim, os descontos são os mesmos, porém permitidos somente para cônjuge e filhos.</p>
              </Accordion.Content>


              <Accordion.Title
                active={ activeIndex === 3 }
                index={ 3 }
                onClick={ handleActiveIndex }
                className='accordionAsk'
              >
                <Icon name='dropdown' />
                Estou com dúvidas em relação ao meu cartão Flash!
              </Accordion.Title>
              <Accordion.Content
                active={ activeIndex === 3 }
                className='accordionAnswer'
              >
                <p>A Flash disponibiliza uma central de ajuda bem completa, basta buscar a informação que precisa &nbsp;
                  <a
                    href="https://beneficios.flashapp.com.br/faq/usu%C3%A1rio-d%C3%BAvidas-do-usu%C3%A1rio-do-benef%C3%ADcio"
                    target='_blank'
                    rel='noopener noreferrer'
                    style={ { textDecoration: 'underline', color: '#ed165b' } }
                  >
                    Aqui
                  </a>
                </p>

              </Accordion.Content>


            </Accordion>
          </div>
        </ContainerMainBenefits>

        { openModalImage.open && (
          <Modal open={ true } closeOnDimmerClick onClose={ handleCloseModalImage }>
            <Modal.Content image>
              <Image size='massive' src={ openModalImage.image } wrapped />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={ () => handleCloseModalImage() } color='red'>
                Fechar
              </Button>
            </Modal.Actions>
          </Modal>
        ) }

      </MainContainer>
    </Layout >
  );
}

export default Benefits;
