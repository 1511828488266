import { IDataUser } from "../interfaces/IUser";
import { getToken } from "../storage/login.storage";
import { ControllerTimeOut } from "../util/controllerTimeOut";
import apiProvider from "./api.provider";

const END_POINT = `WsRestCpxLista`;

async function useUserData() {
  const { controller, id } = ControllerTimeOut();

  const config = {
    body: JSON.stringify({
      alias: "SRA",
      alias2: "",
      empresa: "0101",
      token: getToken(),
      url: "http://protheus.coopex.coop.br:7810/rest",
      signal: controller.signal,
    }),
  };

  try {
    const response = await apiProvider.post<IDataUser>(END_POINT, { ...config });
    return response;
  } catch (error) {
    throw new Error(error as string);
  } finally {
    clearTimeout(id);
  }
}

export default useUserData;
