import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Menu, MenuItemProps, Sidebar } from 'semantic-ui-react';
import { useMenuContext } from '../../contexts/MenuContext';
import { IMenuProps, MenuItems } from '../../data/MenuItems';

export interface IPropsSideBar {
  activeSideBar: boolean;
  setActiveSideBar(active: boolean): void;
}

const AsideBar = ({ activeSideBar, setActiveSideBar }: IPropsSideBar) => {
  const navigate = useNavigate();
  const { setSelectedMenuItem, selectedMenuItem } = useMenuContext();

  const handleClickMenuItem = (e: unknown, { name }: MenuItemProps) => {
    if (name) {
      navigate(`/${ name.toLowerCase() }`);
      setSelectedMenuItem(name.toLocaleLowerCase());
    }
  };

  return (
    <div style={ { zIndex: 999 } }>
      <Sidebar
        as={ Menu }
        animation='overlay'
        direction='left'
        icon='labeled'
        vertical
        visible={ activeSideBar }
        onHide={ () => setActiveSideBar(false) }
        style={ { 'backgroundColor': 'white' } }
      >
        <Menu.Item as='a'>
          <Icon
            name='close'
            onClick={ () => setActiveSideBar(false) }
            color='red'
          />
        </Menu.Item>

        { MenuItems.map(({ name, icon }: IMenuProps, index: number) => {
          return (
            <Menu.Item key={ index } name={ name } onClick={ handleClickMenuItem }>
              <Icon size='big'
                name={ icon }
                className={ selectedMenuItem === name.toLowerCase()
                  ? 'menuIcon activeMenuIcon'
                  : 'menuIcon'
                }
              />
              { name }
            </Menu.Item>
          );
        }) }
      </Sidebar>
    </div>
  );
};

export default AsideBar;