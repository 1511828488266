import { IDataUser } from "../interfaces/IUser";
import apiProvider from "./api.provider";

const END_POINT = `WSRESTCPXALT`;

const updateUserData = async (data: IDataUser) => {
  const config = {
    body: JSON.stringify({ ...data }),
  };

  try {
    const response = await apiProvider.put<IDataUser>(END_POINT, { ...config });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error as string;
  }
};

export default updateUserData;
