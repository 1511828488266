import styled from "styled-components";

export const ModalStyle = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;

  span {
    display: block;
    font: 300 1.6rem ${({ theme }) => theme.fonts.mainFont};
    margin: 1rem;
  }

  p {
    font: 300 1.2rem ${({ theme }) => theme.fonts.mainFont};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    margin-top: 2rem;
  }

  .ui.buttons .or:before {
    background-color: white;
    color: black;
  }

  input {
    font-size: 22px !important;
    height: 55px !important;
    width: 50px !important;
  }
`;

export const EmailContainer = styled("div")`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
`;
