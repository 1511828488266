import { IAuth, IRequestCode } from "../interfaces/IAuth";
import { getToken, saveToken } from "../storage/login.storage";
import { ControllerTimeOut } from "../util/controllerTimeOut";
import apiProvider from "./api.provider";

export class Auth {
  async useLogin(emailEncode: string, passwordEncode: string) {
    const { controller, id } = ControllerTimeOut();

    const END_POINT = `wsrestcpxlog/${emailEncode}/${passwordEncode}`;
    const config = { signal: controller.signal };

    try {
      const response = await apiProvider.get<IAuth>(END_POINT, config);
      if (response.parsedBody?.token) saveToken(response.parsedBody?.token);
      return response;
    } catch (error) {
      throw error as string;
    } finally {
      clearTimeout(id);
    }
  }

  async useAuthEmailOrSms(email: string, sendBy: "email" | "sms") {
    const { controller, id } = ControllerTimeOut();

    const END_POINT = `wsrestcpcodpass/genderCode/${email}?sendBy=${sendBy}`;
    const config = { signal: controller.signal };

    try {
      const response = await apiProvider.put<IRequestCode>(END_POINT, { ...config });
      return response;
    } catch (error) {
      console.log(error);
      throw error as string;
    } finally {
      clearTimeout(id);
    }
  }
  async useCodeValidation(email: string, code: string) {
    const { controller, id } = ControllerTimeOut();

    const END_POINT = `wsrestcpcodpass/validCode/${email}?code=${code}`;
    const config = { signal: controller.signal };

    try {
      const response = await apiProvider.post<IAuth>(END_POINT, { ...config });
      if (response.parsedBody?.token) saveToken(response.parsedBody?.token);
      return response;
    } catch (error) {
      throw error as string;
    } finally {
      clearTimeout(id);
    }
  }

  async useChangePassword(password: string) {
    const { controller, id } = ControllerTimeOut();
    const token = getToken();

    const END_POINT = `wsrestcppass`;

    const config = {
      body: JSON.stringify({ token, password }),
      signal: controller.signal,
    };

    try {
      const response = await apiProvider.put<IAuth>(END_POINT, { ...config });
      if (response.parsedBody?.token) saveToken(response.parsedBody?.token);
      return response;
    } catch (error) {
      throw new Error(error as string);
    } finally {
      clearTimeout(id);
    }
  }
}
