const TOKEN = 'token';
const LOGIN = 'automaticLogin';

export const tokenUser = () =>
  JSON.parse(localStorage.getItem(TOKEN) || 'false');
export const loginAutomatic = () =>
  JSON.parse(localStorage.getItem(LOGIN) || 'false');

export const getToken = () => {
  const result = tokenUser();
  if (result !== undefined) {
    return result;
  }
  return false;
};

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN, JSON.stringify(token));
};
