import { Button, Modal } from 'semantic-ui-react';

export interface IModalProps {
  title: string;
  message: string;
  visible: boolean;
  onClose(): void;
}

const ErrorModal: React.FC<IModalProps> = ({
  title,
  message,
  visible,
  onClose,
}: IModalProps) => {
  return (
    <Modal
      closeIcon
      dimmer='blurring'
      closeOnDimmerClick
      onClose={ () => onClose() }
      open={ visible }
      size='mini'
    >
      <Modal.Header>{ title }</Modal.Header>
      <Modal.Content>
        <Modal.Description style={ { color: 'red', fontSize: '20px' } }>
          { message }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='green' onClick={ () => onClose() }>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};


const SuccessModal: React.FC<IModalProps> = ({
  title,
  message,
  visible,
  onClose,
}: IModalProps) => {
  return (
    <Modal
      closeIcon
      dimmer='blurring'
      closeOnDimmerClick
      onClose={ () => onClose() }
      open={ visible }
      size='mini'
    >
      <Modal.Header>{ title }</Modal.Header>
      <Modal.Content>
        <Modal.Description style={ { fontSize: '20px' } }>
          { message }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='green' onClick={ () => onClose() }>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export { ErrorModal, SuccessModal };
