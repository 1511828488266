import styled from "styled-components";

export const FiapMainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .fiapContent {
    color: black;
    display: flex;
    font: 300 1.2rem ${({ theme }) => theme.fonts.mainFont} !important;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .fiapHeaderColor {
    color: ${({ theme }) => theme.colors.fiapColor};
  }

  .highLigthText {
    font-size: 1.3rem !important;
    font-style: italic;
    font-weight: 600;
  }
`;
