import { useState, useEffect } from "react";

function useTimer() {
  const TIMER_INTERVAL = 1000; // 1 segundo
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (counter > 0) {
      interval = setTimeout(() => {
        setCounter(counter - 1);
      }, TIMER_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [counter]);

  return [counter, setCounter] as const;
}

export default useTimer;
