import styled from "styled-components";

export const HeaderMain = styled.div`
  justify-content: flex-end;

  .dropdown {
    color: ${({ theme }) => theme.colors.primary};
  }

  .dropdown.icon {
    font-size: 1.5rem !important;
  }

  .dropdownItem {
    font-size: 16px !important;
    font-family: ${({ theme }) => theme.colors.primary};
  }
`;

export const HeaderContent = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.colors.primary};
  justify-content: flex-end;
  padding-right: 2rem;
`;

export const ProfileGroup = styled.div`
  .userName {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    color: white;
    display: flex;
    font-size: 18px;
    height: 2.8rem;
    justify-content: center;
    text-align: center;
    width: 2.8rem;
  }

  .userIcon {
    background-color: ${({ theme }) => theme.colors.primary};
    box-shadow: none !important;
    color: white;
  }
`;
