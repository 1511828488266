export default {
  colors: {
    asideColor: "#EEE0E5",
    black: "#000",
    danger: "#e83f5b",
    dark: "#121214",
    divider: "#dededf",
    fiapColor: " #ed165b",
    flashColor: "#ff3d81",
    gray500: "#a8a8b3",
    gray800: "#29292e",
    gympassColor: "#fe122e",
    light: "#f1f1f1",
    link: "#5CACEE",
    orangered: "#FF5349",
    primary: "#ef6f24",
    primaryLight: "#ffbd77",
    secondary: "#114e8b",
    success: "#12a454",
    text: "#4F4F4F",
    white: "#fff",
  },

  fonts: {
    bold: "Roboto_700Bold",
    mainFont: "Josefin Sans, sans-serif",
    regular: "Roboto_400Regular,",
  },
};
