import styled from "styled-components";

export const FooterMain = styled.footer`
  align-items: center;
  background-color: white;
  bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  display: flex;
  height: 40px;
  padding: 1rem;
  position: fixed;
  right: 0;
  width: calc(100% - 5.4rem);
  z-index: 2;
`;
export const FooterContent = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  width: 100%;
`;
