import {
  Button,
  Container,
  Header,
  List,
} from 'semantic-ui-react';

import gympass_discount_image from '../../../assets/images/benefits/gympass.png';
import { FiapMainContainer } from './styles';
import { IHandleOpenModalImage } from '../../../interfaces/IModalImage';

export const GympassModule = ({
  handleOpenModalImage,
}: IHandleOpenModalImage) => {
  return (
    <FiapMainContainer>
      <div>
        <Container text textAlign='left' className='gympassContent'>
          <Header
            as='h1'
            size='large'
            className='benefitHeaderTitle gympassColor'
          >
            Gympass
          </Header>
          <p>
            O GYMPASS É UMA ASSINATURA COMPLETA QUE OFERECE<br></br> A MAIOR
            REDE DE ACADEMIAS, ESTÚDIOS E APLICATIVOS DE BEM-ESTAR.
          </p>

          <List>
            <List.Item>
              <List.Icon name='check' color='green' />
              <List.Content>Agende um personal trainer</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='check' color='green' />
              <List.Content>Treine na academia</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='check' color='green' />
              <List.Content>Acesse seu guia de nutrição</List.Content>
            </List.Item>
          </List>

          <h2>
            <strong>Na palma da mão</strong>
          </h2>
          <p>
            INFINITAS POSSIBILIDADES DE CUIDAR DO SEU BEM-ESTAR, REUNIDAS NUM SÓ
            LUGAR.<br></br> BAIXE O APP E DESCUBRA.
          </p>

          <Button
            content='VER DESCONTOS'
            size='small'
            className='benefitButton'
            secondary
            onClick={ () => handleOpenModalImage(gympass_discount_image) }
          />
        </Container>
      </div>
    </FiapMainContainer>
  );
};
