import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Main } from './pages/Main';
import { Login } from './pages/Login';
import { ChangePass } from './pages/ChangePass';
import { Home } from './pages/Home';
import { Benefits } from './pages/Benefits';
import { Extracts } from './pages/Extracts';
import { Manual } from './pages/Manuals';
import { Profile } from './pages/Profile';

function App() {
  return (
    <Routes>
      <Route path='/' element={ <Main /> } caseSensitive />
      <Route path='/login' element={ <Login /> } />
      <Route path='/troca/senha' element={ <ChangePass /> } caseSensitive />
      <Route path='/home' element={ <Home /> } caseSensitive />
      <Route path='/extratos' element={ <Extracts /> } caseSensitive />
      <Route path='/beneficios' element={ <Benefits /> } caseSensitive />
      <Route path='/manual' element={ <Manual /> } caseSensitive />
      <Route path='/perfil' element={ <Profile /> } caseSensitive />
    </Routes>
  );
}

export default App;
