import styled, { keyframes } from "styled-components";
import { device } from "../../global/styles/global";

const opacityAnimation = keyframes`
  50% {  width: 200px;  color: 'black'} 
  80% {  background-color: #114e8b; opacity: 0.9 }
  `

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  font: 300 1.2rem ${({ theme }) => theme.fonts.mainFont};   

  .userName {
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
  }

  .titleBenefits {
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.secondary};
    width: 100%;
    margin-bottom: 2.2rem !important;
  }

  .titleAbout {
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.secondary};
    width: 100%;
    margin-top: 4rem !important;
    margin-bottom: 0.5rem !important;
  }

  .btnKnowMore {       
    margin-top: 18px;
    width: 240px;
    animation-name: ${opacityAnimation};
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
`;

export const CarouselContainer = styled.div`
  z-index: 2;
  .carousel {
    display: flex;
    width: 96% !important;
    margin: auto;
    align-self: center;
  }
`;

export const ContainerAboutSite = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  

  .contentParagrCoopex {
    align-items: flex-start !important;
    border-radius: 10px;
    font-size: 1.2rem !important;
    padding: 20px 10px;
    width: 90%;
  }

  @media ${device.laptop} {
    .contentParagrCoopex {
      font-size: 1rem !important;
      width: 100%;
    }
  }
`;
