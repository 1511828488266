import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Input, Modal, Progress } from 'semantic-ui-react';
import ReactCodeInput from 'react-code-input'
import { EmailContainer, ModalStyle } from './styles';
import { Auth } from '../../services/auth.service';
import { encode } from 'js-base64';
import { toast } from 'react-toastify';
import useTimer from '../../hooks/UseTimer';
import { checkEmail } from '../../helper';
import theme from '../../global/styles/theme';



export interface IForgotPasswordModal {
  visible: boolean;
  email: string;
  onClose(): void;
}


const ForgotPasswordModal: React.FC<IForgotPasswordModal> = ({ onClose, visible, email }: IForgotPasswordModal) => {
  const auth = new Auth();
  const navigate = useNavigate();
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [smsLoading, setSmsLoading] = useState<boolean>(false);
  const [codeLoading, setCodeLoading] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [sendByCode, setSendByCode] = useState<'email' | 'sms'>('sms');
  const [code, setCode] = useState('');
  const [changeEmail, setChangeEmail] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>('');
  const [counter, setCounter] = useTimer();


  function emailVerify(): void {
    setChangeEmail(false);
    if (newEmail) {
      if (!checkEmail(newEmail)) {
        toast.error("Email inválido..."), setDisableButtons(true);
        return;
      }
    }
    setDisableButtons(false);
  }

  function goToChangePass(): void {
    const emailBase64 = encode(newEmail || email);
    const state = { emailBase64: emailBase64 };
    navigate('/troca/senha', { state });
  }


  async function codeRequest(sendBy: 'email' | 'sms') {
    const id = toast.loading("Por favor aguarde...")
    try {

      setSendByCode(sendBy);

      if (sendBy === "email") {
        setEmailLoading(true);
        setSmsLoading(false);
      } else {
        setEmailLoading(false);
        setSmsLoading(true);
      }

      const { status, parsedBody } = await auth.useAuthEmailOrSms(newEmail || email, sendBy);

      if ((status === 200 || status === 201) && parsedBody) {
        toast.update(id, { render: parsedBody?.info || parsedBody?.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        setCounter(parsedBody?.countdown || 0)
        return;
      }


    } catch (error) {
      toast.update(id, { render: `${ error }` || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    } finally {
      setEmailLoading(false);
      setSmsLoading(false);
    }

  }
  async function codeVerify() {
    const id = toast.loading("Por favor aguarde...")
    setCodeLoading(true);

    try {
      const { status, parsedBody } = await auth.useCodeValidation(newEmail || email, code);

      if ((status === 200 || status === 201) && parsedBody) {
        toast.dismiss();
        goToChangePass();
        return;
      }

    } catch (error) {
      toast.update(id, { render: `${ error }` || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });

    } finally {
      setCodeLoading(false);
    }

  }


  return (
    <Fragment>

      <Modal
        closeIcon
        closeOnDimmerClick
        onClose={ () => { onClose(), setCode(''), setNewEmail(''), setDisableButtons(false) } }
        open={ visible }
        size='tiny'
      >

        <Modal.Header >
          Recuperação de senha para:
          <EmailContainer>
            {
              changeEmail
                ? <Input
                  placeholder="Informe o email"
                  focus
                  value={ newEmail }
                  size='massive'
                  onChange={ ({ target }) => setNewEmail(target.value) }
                  onMouseOut={ emailVerify }
                />
                : <span style={ { color: `${ disableButtons ? "red" : theme.colors.secondary }` } }>{ newEmail || email }</span>
            }
            {
              !changeEmail &&
              <Icon
                onClick={ () => setChangeEmail(true) }
                name='sign-in alternate'
                size='large'
                style={ { marginLeft: '5px', cursor: 'pointer' } } />
            }

          </EmailContainer>
        </Modal.Header>

        <ModalStyle >
          <Modal.Content scrolling  >
            <span>Solicitar código por: </span>

            <Modal.Description >
              <Button.Group>
                {/* <Button
                  positive={ sendByCode === "email" }
                  loading={ emailLoading }
                  disabled={ counter !== 0 || emailLoading || smsLoading || codeLoading || disableButtons }
                  size='big'
                  onClick={ () => codeRequest('email') }>E-mail</Button>
                <Button.Or text='ou' /> */}
                <Button
                  positive={ sendByCode === "sms" }
                  loading={ smsLoading }
                  disabled={ counter !== 0 || emailLoading || smsLoading || codeLoading || disableButtons }
                  size='big'
                  onClick={ () => codeRequest('sms') }> SMS</Button>
              </Button.Group>

              <p> Digite o código recebido </p>

              <ReactCodeInput
                disabled={ false }
                fields={ 6 }
                onChange={ (value) => setCode(value) }
                name="code"
                type='text'
                placeholder='code'
                inputMode={ 'numeric' }
                autoFocus
              />

            </Modal.Description>

            {
              counter > 0 &&
              (
                <>
                  <h1> Solicite um novo código em  <strong> { counter }  </strong> segundos</h1>
                  <Progress value={ counter } indicating total={ 60 } inverted size='tiny' />
                </>)
            }

          </Modal.Content>
        </ModalStyle>

        <Modal.Actions>
          <Button
            loading={ codeLoading }
            disabled={ code.length < 6 || emailLoading || smsLoading || disableButtons }
            positive onClick={ () => codeVerify() }>
            Validar Código
          </Button>
        </Modal.Actions>

      </Modal >
    </Fragment>
  );
};



export { ForgotPasswordModal };
