import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { CarouselContainer, ContainerAboutSite, ContainerMain } from './styles';

import flash from '../../assets/images/home/flash.png';
import fiap from '../../assets/images/home/fiap.png';
import gympass from '../../assets/images/home/gympass.png';

import { Image, Header, Button, List } from 'semantic-ui-react';
import { UserReducerInitialState } from '../../redux/reducers/User';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import { useMenuContext } from '../../contexts/MenuContext';
import { getFirstName } from '../../helper/getFirstName';
import Cookies from 'js-cookie';
import LgpdModal from '../../components/LgpdModal';

export const Home = () => {
  const [openLGPD, setOpenLGPD] = useState(false);

  const lgpdCookie = Cookies.get('lgpd') as string;
  const { setSelectedMenuItem } = useMenuContext();

  useEffect(() => {
    setSelectedMenuItem('home');
  });

  useEffect(() => {
    if (!lgpdCookie) setOpenLGPD(true);
  }, [])


  const navigate = useNavigate();
  const { userName } = useSelector(
    (state: { user: UserReducerInitialState }) => state.user
  );


  return (
    <Layout>
      <ContainerMain>
        <Header as='h3' className='userName'>
          Bem vindo,{ ' ' }
          { getFirstName(userName) }
        </Header>
        <Header as='h3' dividing className='titleBenefits'>
          Conheça seus benefícios
        </Header>
        <hr></hr>
        <CarouselContainer>
          <Carousel
            animationHandler='slide'
            autoPlay
            infiniteLoop
            interval={ 6000 }
            showStatus={ false }
            showThumbs={ false }
            className='carousel'
          >
            <Image src={ gympass } alt='Image Gympass' fluid />
            <Image src={ flash } alt='Image Flash' fluid />
            <Image src={ fiap } alt='Image Fiap' fluid />
          </Carousel>
        </CarouselContainer>

        <Button
          content='Saiba mais'
          size='big'
          color='orange'
          className='btnKnowMore'
          onClick={ () => navigate('/beneficios') }
        />

        <Header as='h3' dividing className='titleAbout'>
          Sobre esse ambiente
        </Header>

        <ContainerAboutSite>
          <div className='contentParagrCoopex'>
            <p>A COOPEX DESENVOLVEU ESSE ESPAÇO PENSANDO EM VOCÊ!</p>
            <p>
              DE FORMA CLARA E OBJETIVA, BUSCAMOS REUNIR TUDO EM UM SÓ LUGAR,
              PARA O SEU CONFORTO E COMODIDADE, GARANTINDO:
            </p>

            <List>
              <List.Item>
                <List.Icon name='check' color='green' />
                <List.Content>Transparência</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='check' color='green' />
                <List.Content>Agilidade</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='check' color='green' />
                <List.Content>E bem-estar aos nossos cooperados</List.Content>
              </List.Item>
            </List>
            <br></br>
            <p>
              <strong>
                CASO TENHA DÚVIDAS/RECLAMAÇÕES OU SUGESTÕES, NÃO PERCA TEMPO,
                ENTRE EM CONTATO CONOSCO, BASTA CLICAR NO ÍCONE DO WHATSAPP.
              </strong>
            </p>
          </div>
          <LgpdModal visible={ openLGPD } onClose={ () => setOpenLGPD(false) } />

        </ContainerAboutSite>
      </ContainerMain>
    </Layout >
  );
};

