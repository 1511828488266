import { createSlice } from "@reduxjs/toolkit";
import { FieldValues } from "../../../@types/RestField";
import { IActionDataUser } from "./actions";

export interface UserReducerInitialState {
  userName: string;
  enrollment: string;
  dataUser: FieldValues;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userName: "",
    enrollment: "",
    dataUser: {} as FieldValues,
  },
  reducers: {
    setDataUser: (state, action: IActionDataUser) => {
      state.dataUser = {
        ...state.dataUser,
        ...action.payload.value,
      };
    },

    setInfoUser: (state, action) => {
      state.userName = action.payload.name;
      state.enrollment = action.payload.enrollment;
    },
  },
});

export const { setDataUser, setInfoUser } = userSlice.actions;
export default userSlice.reducer;
