import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Icon, Menu, Image, MenuItemProps } from 'semantic-ui-react';

import { MenuContainer, SideBarContainer, LogoContainer } from './styles';

import { IMenuProps, MenuItems } from '../../data/MenuItems';

import logo from '../../assets/images/logoCoopex.png';
import AsideBar from '../ASideBar';

import { useMenuContext } from '../../contexts/MenuContext';

const MainMenu: React.FC = () => {
  const navigate = useNavigate();
  const [activeSideBar, setActiveSideBar] = useState(false);
  const { selectedMenuItem, setSelectedMenuItem } = useMenuContext();

  const handleClickMenuItem = (e: unknown, data: MenuItemProps) => {
    if (data) {
      const name = data.name?.toLowerCase() as string;
      setSelectedMenuItem(name);
      navigate(`/${ name }`);
    }
  };
  return (

    <MenuContainer>
      <SideBarContainer>
        <LogoContainer>
          <Image src={ logo } alt='logo coopex' className='logo' />
        </LogoContainer>
        <div>
          <Icon
            name='sidebar'
            className='iconSideBar'
            size='big'
            fitted
            onClick={ () => setActiveSideBar(true) }
          />
        </div>
        <AsideBar
          activeSideBar={ activeSideBar }
          setActiveSideBar={ setActiveSideBar }
        />
      </SideBarContainer>

      <div className='menuMain'>
        <Menu vertical compact borderless floated fluid icon text stackable>
          { MenuItems.map(({ name, icon }: IMenuProps, index: number) => {
            return (
              <MenuItem
                key={ index }
                name={ name }
                active={ selectedMenuItem === name }
                onClick={ handleClickMenuItem }
              >
                <Icon
                  size='big'
                  name={ icon }
                  className={
                    selectedMenuItem === name.toLowerCase()
                      ? 'menuIcon activeMenuIcon'
                      : 'menuIcon'
                  }
                />
                { name }
              </MenuItem>
            );
          }) }
        </Menu>
      </div>
    </MenuContainer>

  );
};

export default MainMenu;
