import React, { useEffect } from 'react';
import { Grid, Header, Label, Segment } from 'semantic-ui-react';
import { ManualContainer } from './styles';

import ata2019 from '../../assets/pdfs/atas/Ata de Assembléia_2019.pdf';
import ata2023 from '../../assets/pdfs/atas/Ata de Assembléia_2023.pdf';
import manual from '../../assets/pdfs/manual/Coopex - Manual.pdf';
import Layout from '../../components/Layout';
import { useMenuContext } from '../../contexts/MenuContext';

export const Manual = () => {

  const { setSelectedMenuItem: setItemMenuSelected } = useMenuContext();

  useEffect(() => {
    setItemMenuSelected('manual');
  }, []);


  return (
    <Layout>
      <ManualContainer>
        <Grid columns={2} relaxed='very' stackable>
          <Grid.Column>
            <Segment size='big' raised>
              <Label as='a' color='blue' ribbon size='big'>
                Atas
              </Label>
              <Header as='h1' dividing />
              <a
                href={ata2019}
                target='_blank'
                rel='noopener noreferrer'
                className='link'
              >
                Assembleia 2019
              </a>
              <p></p>
              <a
                href={ata2023}
                target='_blank'
                rel='noopener noreferrer'
                className='link'
              >
                Assembleia 2023
              </a>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment size='big' raised>
              <Label as='a' color='blue' ribbon size='big'>
                Manual do Cooperado
              </Label>
              <Header as='h1' dividing />
              <a
                href={manual}
                target='_blank'
                rel='noopener noreferrer'
                className='link'
              >
                Acesse aqui
              </a>
            </Segment>
          </Grid.Column>
        </Grid>
      </ManualContainer>
    </Layout>
  );
};
