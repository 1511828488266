export const ExtractData = [
  { key: "lq", text: "Declaração de Liquidos", value: 1 },
  { key: "pd", text: "Recibo de Produção", value: 2 },
  { key: "rd", text: "Informe de Rendimentos", value: 3 },
];

export const Months = [
  { text: "Janeiro", value: "01" },
  { text: "Fevereiro", value: "02" },
  { text: "Março", value: "03" },
  { text: "Abril", value: "04" },
  { text: "Maio", value: "05" },
  { text: "Junho", value: "06" },
  { text: "Julho", value: "07" },
  { text: "Agosto", value: "08" },
  { text: "Setembro", value: "09" },
  { text: "Outubro", value: "10" },
  { text: "Novembro", value: "11" },
  { text: "Dezembro", value: "12" },
];

interface Iyear {
  text: string;
  value: string;
}

const data = new Date();
const year = data.getFullYear();
const years: Iyear[] = [];

export const Years = () => {
  for (let index = 0; index < 5; index += 1) {
    years[index] = {
      text: (year - index).toString(),
      value: (year - index).toString(),
    };
  }
  return years;
};
