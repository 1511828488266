import styled from "styled-components";
import { device } from "../../global/styles/global";

export const MenuContainer = styled.div`
  background-color: white;
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
  height: 100vh;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 4;

  .menuMain {
    height: 100vh;
    margin-top: 110px;
  }

  .menuIcon {
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
  }

  .activeMenuIcon {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .iconSideBar {
    visibility: hidden;
  }

  @media ${device.laptop} {
    .menuMain {
      visibility: hidden;
    }

    .iconSideBar {
      visibility: visible;
    }
  }
`;

export const SideBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  position: fixed !important;
  z-index: 5;

  .logo {
    width: 50px;
  }

  .visible {
    display: flex;
  }

  .iconSideBar {
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
`;
