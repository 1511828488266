import {
  Button,
  Container,
  Header
} from 'semantic-ui-react';

import fiap_discount_image from '../../../assets/images/benefits/fiap.jpeg';
import { FiapMainContainer } from './styles';
import { IHandleOpenModalImage } from '../../../interfaces/IModalImage';

export const FiapModule = ({ handleOpenModalImage }: IHandleOpenModalImage) => {
  return (
    <FiapMainContainer>
      <div>
        <Container text textAlign='left' className='fiapContent'>
          <Header
            as='h1'
            size='large'
            className='benefitHeaderTitle fiapHeaderColor'
          >
            Fiap
          </Header>
          <p>
            A FIAP É O CENTRO DE EXCELÊNCIA EM TECNOLOGIA MAIS RESPEITADO DO
            PAÍS.
          </p>

          <p>
            EM <strong className='highLigthText green'>2019</strong>, A FIAP
            RECEBEU 10 COMISSÕES DE AVALIAÇÃO DO{ ' ' }
            <strong className='highLigthText orange'>MEC.</strong> <br></br> E
            OBTEVE <strong>10 NOTAS</strong> MÁXIMAS EM SEUS CURSOS DE GRADUAÇÃO.
          </p>

          <p>
            E A <strong>COOPEX</strong> NÃO PODERIA DEIXÁ-LO FORA DESSA, PORQUE ACREDITA NO SEU POTENCIAL.
          </p>

          <h2>
            <strong>
              Válido para cônjuge e filhos | valores diferenciados para grupos.
            </strong>
          </h2>
          <Button
            content='SAIBA MAIS...'
            size='small'
            className='benefitButton'
            primary
            onClick={ () => handleOpenModalImage(fiap_discount_image) }
          />
        </Container>
      </div>
    </FiapMainContainer>
  );
};
