import { INewPass } from "../interfaces/IAuth";

export const checkEmail = (mail: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
};

export const hasMinimumSize = (password: string, repitPassword?: string): boolean => {
  if (repitPassword !== undefined) {
    return password.length >= 5 && repitPassword.length >= 5;
  }

  return password.length >= 5;
};

const isEqual = (password: string, repitPassword: string): boolean => {
  return password === repitPassword;
};

const hasLowerCaseLetter = (password: string, repitPassword: string): boolean => {
  return /[a-z]/.test(password) && /[a-z]/.test(repitPassword);
};

const hasUpperCaseLetter = (password: string, repitPassword: string): boolean => {
  return /[A-Z]/.test(password) && /[A-Z]/.test(repitPassword);
};

const hasNumber = (password: string, repitPassword: string): boolean => {
  return /[0-9]/.test(password) && /[0-9]/.test(repitPassword);
};

const hasSpecialChar = (password: string, repitPassword: string): boolean => {
  return /[@!#$%^&*()/\\]/.test(password) && /[@!#$%^&*()/\\]/.test(repitPassword);
};

const newPass = {
  haslowercase: false,
  hasuppercase: false,
  hasminimumSize: false,
  hasnumber: false,
  hasspecialchar: false,
  samepass: false,
} as INewPass;

export const validNewPassword = (password: string, repitPassword: string): INewPass => {
  isEqual(password, repitPassword) ? (newPass.samepass = true) : (newPass.samepass = false);
  hasLowerCaseLetter(password, repitPassword) ? (newPass.haslowercase = true) : (newPass.haslowercase = false);
  hasMinimumSize(password, repitPassword) ? (newPass.hasminimumSize = true) : (newPass.hasminimumSize = false);
  hasNumber(password, repitPassword) ? (newPass.hasnumber = true) : (newPass.hasnumber = false);
  hasSpecialChar(password, repitPassword) ? (newPass.hasspecialchar = true) : (newPass.hasspecialchar = false);
  hasUpperCaseLetter(password, repitPassword) ? (newPass.hasuppercase = true) : (newPass.hasuppercase = false);

  return newPass;
};
