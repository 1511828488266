import React, { useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// STYLES
import { HeaderContent, HeaderMain, ProfileGroup } from './styles';

import { getToken } from '../../storage/login.storage';

// COMPONENTS
import { Dropdown, Header, Icon } from 'semantic-ui-react';

import { useUserContext } from '../../contexts/UserContext';
import { UserReducerInitialState } from '../../redux/reducers/User';
import Cookies from 'js-cookie';
import theme from '../../global/styles/theme';

export const DefaultHeader: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { restDataUser } = useUserContext();

  useEffect(() => {
    if (!getToken()) navigate('/login');

    async function getDataUser() {
      try {
        await restDataUser();
      } catch (error) {
        console.log(error as string);
      }
    }
    getDataUser();
  }, []);

  const { userName } = useSelector(
    (state: { user: UserReducerInitialState }) => state.user
  );

  const logOut = () => {
    Cookies.remove('keyU');
    Cookies.remove('keyP');
    Cookies.remove('AutomaticLogin');
    navigate('/');
  };

  return (
    <HeaderMain>
      <Header style={ { border: "none", borderBottom: `1px solid ${ theme.colors.divider } ` } } attached="top" >

        <HeaderContent>
          <ProfileGroup>
            { userName ?
              <div className='userName'>
                <span>{ userName.charAt(0) }</span>
              </div>
              :
              <Icon name='user outline' circular className='userIcon' size='large' fitted />
            }
          </ProfileGroup>
          <Dropdown direction='left' floating className='dropdown'>
            <Dropdown.Menu>
              <Dropdown.Item
                text='Meu dados'
                onClick={ () => navigate('/perfil', { state: `${ location.pathname }` }) }
                className='dropdownItem'
              />
              <Dropdown.Item
                text='Encerrar Sessão'
                onClick={ logOut }
                className='dropdownItem'
              />
            </Dropdown.Menu>
          </Dropdown>

        </HeaderContent>

      </Header>
    </HeaderMain>
  );
};
