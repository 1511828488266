import React, { useEffect, useState } from 'react';
import { FormHandler, MouseEvent } from '../../@types/types';
import { useNavigate } from 'react-router-dom';
import data from '../../../package.json';

// STYLES
import {
  MainContainer,
  LoginContainer,
  Bg,
  CheckBoxContainer,
  LogoContainer,
} from './styles';

//HELPERS
import { Auth } from '../../services/auth.service';
import { checkEmail, hasMinimumSize } from '../../helper';
import { encode } from 'js-base64';

// IMAGENS
import logo from '../../assets/images/logo.png';

// COOKIE
import {
  saveCookieLogin,
  getCookieLogin,
  getCookieAutomaticLogin,
  saveCookieAutomaticLogin,
} from '../../util/cookieSave';

// COMPONENTS
import {
  Input,
  Form,
  Button,
  Image,
  Checkbox,
  CheckboxProps,
  Icon,
} from 'semantic-ui-react';
import { ErrorModal } from '../../components/MessageModal';

import { ForgotPasswordModal } from '../../components/ForgotPasswordModal';
import LgpdModal from '../../components/LgpdModal';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export function Login() {
  const navigate = useNavigate();
  const auth = new Auth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsgFromServer] = useState('');
  const [buttonState, setButtonState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [checkBoxRemember, setCheckBoxRemember] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [openLGPD, setOpenLGPD] = useState(false);
  const lgpdCookie = Cookies.get('lgpd') as string;

  useEffect(() => {
    // SE A OPCAO DE LEMBRAR-ME FOI MARCADO, PREENCHE OS CAMPOS DE EMAIL E SENHA
    function checkAutomaticLogin() {
      if (getCookieAutomaticLogin()) {
        setEmail(getCookieLogin().login);
        setPassword(getCookieLogin().password);
        setCheckBoxRemember(true);

        const element = document.getElementById('button-login');
        if (element) element.click();
      }
    }

    if (getCookieLogin().login && getCookieLogin().password)
      checkAutomaticLogin();
  });

  function handleCheckBox(e: unknown, data: CheckboxProps) {
    if (data) {
      const isChecked = data.checked as boolean;
      if (isChecked && !lgpdCookie || lgpdCookie === "false") {
        Swal.fire({
          title: 'LGPD',
          html: 'Para ativar esse recurso, você precisa aceitar a coleta de informações, gostaria de Revisar ?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Revisar',
          cancelButtonText: 'Cancelar'
        }
        ).then((result) => {
          if (result.isConfirmed) {
            setOpenLGPD(true);
            //navigate('/')
          }
        })
        return;
      }
      setCheckBoxRemember(isChecked);
      saveCookieAutomaticLogin(isChecked);
    }
  }


  // PARA RESET DE SENHA
  const resetPassword = async (event: MouseEvent) => {
    event.preventDefault();

    if (email.trim() === '' || !checkEmail(email)) {
      setMsgFromServer('E-mail em branco ou inválido!');
      setOpenErrorModal(true);
      return;
    }

    setForgotPasswordModalOpen(true);
  };


  async function handleSubmit(event: FormHandler) {
    event.preventDefault();
    setMsgFromServer('');
    setLoading(true);

    try {
      const emailEncode = encode(email.toUpperCase());
      const passwordEncode = encode(password);
      const { status, parsedBody } = await auth.useLogin(emailEncode, passwordEncode);

      if ((status === 200 || status === 201) && parsedBody) {
        if (parsedBody?.status == 'erro') {
          setLoading(false);
          setMsgFromServer(parsedBody.msg);
          setOpenErrorModal(true);
          return;
        }

        setMsgFromServer('');
        saveCookieLogin(!!getCookieAutomaticLogin(), email, password);
        navigate('/home');
      }
      else {
        setOpenErrorModal(true);
        setMsgFromServer('Ops, não foi possível realizar o login !');
      }

    } catch (error) {
      console.log(error);
      setOpenErrorModal(true);
      setMsgFromServer(`${ error }` || 'Ops, algo deu errado !');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    function validate() {
      if (checkEmail(email) && hasMinimumSize(password)) {
        // habilita o botao de login
        setButtonState(false);
        return;
      }
      setButtonState(true);
    }
    validate();
  }, [email, password]);

  return (
    <MainContainer>
      <ForgotPasswordModal
        visible={ forgotPasswordModalOpen }
        onClose={ () => setForgotPasswordModalOpen(false) }
        email={ email }
      />

      <ErrorModal
        title='Mensagem'
        message={ msg }
        visible={ openErrorModal }
        onClose={ () => setOpenErrorModal(false) }
      />

      <Form onSubmit={ handleSubmit }>
        <LoginContainer>
          <Bg />
          <LogoContainer>
            <Image src={ logo } className='logotipo' />
            <p className='text_cooperativa'>PORTAL DO COOPERADO</p>
          </LogoContainer>

          <Form.Field>
            <Input
              type='email'
              icon={ { name: 'user', className: 'icon' } }
              iconPosition='left'
              focus
              size='big'
              placeholder='Usuário'
              value={ email }
              onChange={ ({ target }) => setEmail(target.value) }
              autoComplete='email'
              className='inputLogin'
            />
          </Form.Field>
          <Form.Field>
            <Input
              type={ showPassword ? 'text' : 'password' }
              icon={ { name: 'lock', className: 'icon' } }
              size='big'
              focus
              iconPosition='left'
              placeholder='Senha'
              value={ password }
              onChange={ ({ target }) => setPassword(target.value) }
              autoComplete='password'
              className='inputLogin'
            />
            { showPassword ? (
              <Icon
                name='eye'
                className='eyePassword'
                size='large'
                onClick={ () => setShowPassword(!showPassword) }
              />
            ) : (
              <Icon
                name='eye slash'
                className='eyePassword'
                size='large'
                onClick={ () => setShowPassword(!showPassword) }
              />
            ) }
          </Form.Field>

          <CheckBoxContainer className=''>
            <Checkbox
              label='Lembrar-me'
              type='checkbox'
              className='remember_me'
              value=''
              checked={ checkBoxRemember }
              onChange={ handleCheckBox }
            />

            <p
              className='forgot_password'
              onClick={ (event) => {
                resetPassword(event);
              } }
            >
              Esqueci a senha
            </p>
          </CheckBoxContainer>

          <Button
            content='ENTRAR'
            type='submit'
            id='button-login'
            disabled={ buttonState }
            loading={ loading }
          />
          <p className='version'>{ `v.${ data.version }-stable` }</p>
        </LoginContainer>
      </Form>
      <LgpdModal visible={ openLGPD } onClose={ () => setOpenLGPD(false) } />
    </MainContainer>

  );

}
