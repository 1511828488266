import React, { FC } from 'react';
import { FooterMain, FooterContent } from './styles';
import ethosx from '../../assets/images/footer/ethosx.png';
import { Image, Icon } from 'semantic-ui-react';

const Footer: FC = () => {
  return (
    <FooterMain>
      <FooterContent>
        Feito com &nbsp; <Icon name='heart' color='yellow' size='large'></Icon>{ ' ' }
        por &nbsp;
        <Image src={ ethosx } size='tiny' />
      </FooterContent>
    </FooterMain>
  );
};

export default Footer;
