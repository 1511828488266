import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
  }

  html, body, #root {
    height: 100vh;
  }

  body {
    font:  1rem ${({ theme }) => theme.fonts.mainFont} !important;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3 {
    font:  1.5rem ${({ theme }) => theme.fonts.mainFont} !important;   
  }

  input,
  select, 
  textarea {
    font: 400 1.1rem ${({ theme }) => theme.fonts.mainFont} !important;
  }


  .ui.form .field.field input:-webkit-autofill {   
    border-color: white !important; 
    -webkit-box-shadow: 0 0 0px 1000px #f9fbfd inset !important;
    -webkit-text-fill-color: black !important; 
  }

  .ui.form .field.field input:-webkit-autofill:focus {
    border-color: white !important;
    -webkit-box-shadow: 0 0 0px 1000px #f9fbfd inset !important;
    -webkit-text-fill-color: black !important;    
  }

`;

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1366px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
