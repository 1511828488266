import React from 'react';
import { Form, Input, InputOnChangeData, Label, Tab } from 'semantic-ui-react';

export const UserTab = (
  tab: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: [string, string | any][],
  loading: boolean,
  onChangeData:
    | ((
      event: React.ChangeEvent<HTMLInputElement>,
      data: InputOnChangeData
    ) => void)
    | undefined
) => {
  return (
    <Tab.Pane loading={ loading }>
      { data.length &&
        data.map(([key, value], index) => (
          <Form.Field inline key={ index }>
            <Label size='big'>{ key }</Label>

            <Input
              type='text'
              name={ key }
              tab={ tab }
              value={ value.trim() }
              onChange={ onChangeData }
              className='fieldInput'
              disabled
            />
          </Form.Field>
        )) }
    </Tab.Pane>
  );
};
