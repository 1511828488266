import { useDispatch } from 'react-redux';
import React, { createContext, FC, useContext } from 'react';

import { setDataUser, setInfoUser } from '../redux/reducers/User';

//SERVICES
import useDataUser from '../services/userData.service';

import { TProps } from '../@types/types';
import { IUserContext } from '../interfaces/IUser';

export const UserContext = createContext<IUserContext>({} as IUserContext);

const UserContextProvider: FC<TProps> = ({ children }: TProps) => {
  const dispatch = useDispatch();

  async function restDataUser() {
    try {
      const { status, parsedBody } = await useDataUser();

      if ((status === 200 || status === 201)&& parsedBody) {
        const { pessoal: { Nome, Matricula } } = parsedBody;
        dispatch(setDataUser({ value: parsedBody }));
        dispatch(setInfoUser({ name: Nome, enrollment: Matricula }));
      } else {
        throw new Error("Não foi possível obter os dados !");
      }

    } catch (error) {
      throw new Error(error as string);
    }
  }

  return (
    <UserContext.Provider value={ { restDataUser } }>
      { children }
    </UserContext.Provider>
  );
};

export default UserContextProvider;

export function useUserContext(): IUserContext {
  const context = useContext(UserContext);
  if (!context)
    throw new Error('useDataContext deve ser usado em um UserContextProvider');
  return context;
}
