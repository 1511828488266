import styled from "styled-components";

export const PageDefault = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const BrowserContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  height: 100%;
  margin-left: 5rem;
  width: calc(100% - 6.5rem);
  z-index: 2;
`;

export const ContainerDivider = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 30px;
  width: 90%;
`;

export const ContainerDividerFooter = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  width: 90%;
`;
