import { Image } from 'semantic-ui-react';

import whatsIcon from '../../assets/images/whatsapp_icone.png';
import { WhatAppContainer } from './styles';

export const WhatsModule = () => {
  return (
    <WhatAppContainer>
      <a
        href='https://wa.me/5511996797553'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Image src={ whatsIcon } />
      </a>
    </WhatAppContainer>
  );
};
