import React from 'react';
import UserContextProvider from './UserContext';
import { TProps } from '../@types/types';
import MenuContextProvider from './MenuContext';

const ContextProvider = ({ children }: TProps) => {
  return (
    <UserContextProvider>
      <MenuContextProvider>
        { children }
      </MenuContextProvider>
    </UserContextProvider>
  );
};

export default ContextProvider;
