import {
  Button,
  Container,
  Header,
  List,
} from 'semantic-ui-react';

import flash_discount_image from '../../../assets/images/benefits/flash.jpg';
import { FlashMainContainer } from './styles';
import { IHandleOpenModalImage } from '../../../interfaces/IModalImage';

export const FlashModule = ({
  handleOpenModalImage,
}: IHandleOpenModalImage) => {
  return (
    <FlashMainContainer>
      <div>
        <Container text textAlign='left' className='flashContent'>
          <Header
            as='h1'
            size='large'
            className='benefitHeaderTitle flashColor'
          >
            Flash
          </Header>
          <p>LIBERDADE É TER TUDO EM UM FLASH</p>

          <List>
            <List.Item>
              <List.Icon name='check' color='green' />
              <List.Content>
                Aquela ajuda essencial para pagar boletos de internet, energia,
                telefone
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='check' color='green' />
              <List.Content>
                Aproveite as centenas de ofertas disponíveis exclusivas para
                quem é Flash
              </List.Content>
            </List.Item>
          </List>

          <h2>
            <strong>Tudo, tudo, tudo em um só cartão</strong>
          </h2>
          <p>
            TENHA VÁRIOS BENEFÍCIOS EM UM ÚNICO CARTÃO, ACEITO EM MAIS DE 4
            MILHÕES DE ESTABELECIMENTOS PELA MASTERCARD®️. <br></br>PARA PAGAR É
            SÓ APROXIMAR.
          </p>

          <Button
            content='SAIBA MAIS...'
            size='small'
            className='benefitButton'
            color='pink'
            onClick={ () => handleOpenModalImage(flash_discount_image) }
          />
        </Container>
      </div>
    </FlashMainContainer>
  );
};
