import styled from "styled-components";
import { device } from "../../global/styles/global";

export const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  width: 90%;

  .headerTitle {
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.secondary};
    width: 100%;
    margin-bottom: 2.5rem !important;
    margin-top: 1rem !important;
  }

  .benefitHeaderTitle {
    font-size: 2.4rem !important;
  }

  .benefitButton {
    align-self: center;
    font-size: 1.2rem !important;
    margin-top: 1rem;
    width: 50%;
  }

  .green {
    color: ${({ theme }) => theme.colors.success};
  }

  .orange {
    color: ${({ theme }) => theme.colors.orangered};
  }

  .aside {
    background-color: ${({ theme }) => theme.colors.asideColor};
    box-shadow: 2.5px 2.5px 15px gray;
    display: flex;
    font: 300 1rem ${({ theme }) => theme.fonts.mainFont} !important;
    height: auto;
    padding: 10px;
    width: 30%;
  }

  .accordionAsk {
    font-size: 1.2rem !important;
  }

  .accordionAnswer {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.3rem !important;
    font-weight: 400;
    margin-left: 0.5rem;
  }

  @media ${device.laptop} {
    .benefitButton {
      width: 100%;
    }

    .aside {
      display: none;
    }
  }
`;

export const ContainerMainBenefits = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .benefitCard {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
    margin-bottom: 3rem;
    width: 100%;
    padding-bottom: 1.5rem;
  }
`;
