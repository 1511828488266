import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserReducerInitialState } from '../../redux/reducers/User';
import { useMenuContext } from '../../contexts/MenuContext';

// STYLES
import { ReportContainer } from './styles';

// COMPONENTS
import {
  Button,
  Container,
  DropdownProps,
  Header,
  Icon,
  Select,
} from 'semantic-ui-react';
import { ErrorModal } from '../../components/MessageModal';

// DATA
import {
  ExtractData,
  Years,
  Months,
} from '../../data/ExtractData';

// SERVICE
import useExtract from '../../services/extracts.service';

// HELPER
import { pdfOpen } from '../../helper/pdfOpen';

import { EnumItemsMenu } from '../../data/MenuItems';
import Layout from '../../components/Layout';

export function Extracts() {
  const { setSelectedMenuItem: setItemMenuSelected } = useMenuContext();

  useEffect(() => {
    setItemMenuSelected('extratos');
  }, []);


  const [month, setMonth] = useState('01');
  // 1- DECLARACAO DE LIQUIDOS , 2 - RECIBO DE PRODUCAO, 3 - INFORME DE RENDIMENTOS
  const [report, setReport] = useState('');
  const [year, setYear] = useState('');
  const [loading, setLoading] = useState(false);
  const [msgFromServer, setMsgFromServer] = useState('');
  const [openModal, setOpenModal] = useState(false);


  const { enrollment } = useSelector(
    (state: { user: UserReducerInitialState }) => state.user
  );

  enum EnumItemSelect {
    type = 'Tipo de extrato',
    month = 'Selecione o mes',
    year = 'Selecione o ano',
  }

  const formatYears = () => {
    if (report === '3') {
      const yearsCopy = [...Years()];
      const formattedYears = yearsCopy.slice(1).map(({ value }) => {
        return {
          text: `Ano calendário ${ Number(value) } - Exercício ${ Number(value) + 1 } `,
          value
        }
      })
      return formattedYears;
    }
    return Years();
  }

  const handleChange = (e: unknown, data: DropdownProps) => {
    const value = data.value?.toString();
    const placeHolder = data.placeholder;

    if (value) {
      switch (placeHolder) {
        case EnumItemSelect.type:
          setReport(value);
          break;
        case EnumItemSelect.month:
          setMonth(value);
          break;
        default:
          setYear(value);
          break;
      }
    }
  };

  const getReport = async () => {
    setLoading(true);

    if (!enrollment && enrollment === '') {
      setLoading(false);
      setMsgFromServer('Por favor, faça o login novamente!');
      setOpenModal(true);
      return;
    }

    if (!report) {
      setLoading(false);
      setMsgFromServer('Informe o tipo de extrato !');
      setOpenModal(true);
      return
    }

    if (!year) {
      setLoading(false);
      setMsgFromServer('Informe o ano !');
      setOpenModal(true);
      return
    }


    try {
      let periodo = year;
      // PRODUCÃO
      if (report === '2') {
        periodo = `${ year }${ month }`;
      }

      const { status, parsedBody } = await useExtract(
        enrollment || '',
        periodo,
        report
      );

      if ((status === 200 || status === 201) && parsedBody) {
        if (parsedBody?.status === 'erro') {
          setLoading(false);
          setMsgFromServer('Periodo informado não encontrado!');
          setOpenModal(true);
          return;
        }
        pdfOpen(parsedBody?.file || '');
      }
      else {
        setMsgFromServer('Ops, algo deu errado !');
        setOpenModal(true);
      }


    } catch (error) {
      setMsgFromServer('Ops, algo deu errado !');
      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <ReportContainer>
        <Header as='h4' size='small' className='titleReport'>
          <Icon name={ EnumItemsMenu.extratos } />
          Extratos
        </Header>
        <ErrorModal
          title='Mensagem'
          message={ msgFromServer }
          visible={ openModal }
          onClose={ () => setOpenModal(false) }
        />
        <Select
          loading={ !enrollment }
          placeholder={ EnumItemSelect.type }
          options={ ExtractData }
          onChange={ handleChange }
          fluid
          className='select'
        />

        { report === '2' && (
          <Select
            loading={ !enrollment }
            placeholder={ EnumItemSelect.month }
            options={ Months }
            onChange={ handleChange }
            value={ month }
            fluid
            className='select'
          />
        ) }

        <Select
          loading={ !enrollment }
          placeholder={ EnumItemSelect.year }
          options={ formatYears() }
          onChange={ handleChange }
          value={ year }
          fluid
          className='select'
        />

        <Button
          loading={ !enrollment || loading }
          disabled={ !enrollment }
          onClick={ () => getReport() }
          fluid
          className='btnConfirm'
        >
          CONFIRMAR
        </Button>
        <Container className='space' />
      </ReportContainer>
    </Layout>
  );
}


