import Cookies from "js-cookie";
import { encrypt, descrypt } from "./cripto";

const keyLogin = Cookies.get("keyU") as string;
const keyPass = Cookies.get("keyP") as string;

export const saveCookieAutomaticLogin = (enable: boolean) => {
  if (enable && enable !== undefined) {
    Cookies.set("AutomaticLogin", "ok", { path: "", expires: 180 });
  } else {
    Cookies.remove("AutomaticLogin");
  }
};

export const getCookieAutomaticLogin = () => {
  const loginAutomatic = Cookies.get("AutomaticLogin") as string;
  return loginAutomatic;
};

export const saveCookieLogin = (enable: boolean, email: string, password: string) => {
  // Para não resetar a expiração uma vez definidda, retorna
  if (keyLogin != undefined && keyPass != undefined) return;

  // CRIPTOGRAFIA DOS DADOS
  const emailCrypto = encrypt(email) as unknown as string;
  const passwordCrypto = encrypt(password) as unknown as string;

  if (enable && enable !== undefined) {
    Cookies.set("keyU", emailCrypto, { path: "/", expires: 180 });
    Cookies.set("keyP", passwordCrypto, { path: "/", expires: 180 });
  } else {
    Cookies.remove("keyU");
    Cookies.remove("keyP");
  }
};

export const getCookieLogin = () => {
  // DESCRIPTOGRAFA
  const login = descrypt(keyLogin);
  const password = descrypt(keyPass);

  return {
    login,
    password,
  };
};
