import { IExtract } from "../interfaces/IAuth";
import { ControllerTimeOut } from "../util/controllerTimeOut";
import apiProvider from "./api.provider";

const useExtract = async (matricula: string, periodo: string, relatorio: string) => {
  const { controller, id } = ControllerTimeOut();

  const END_POINT = `WSRELCOOP/`;

  const config = {
    body: JSON.stringify({
      matricula,
      periodo,
      relatorio,
      signal: controller.signal,
    }),
  };

  try {
    const response = await apiProvider.post<IExtract>(END_POINT, { ...config });
    return response;
  } catch (error) {
    throw new Error(error as string);
  } finally {
    clearTimeout(id);
  }
};

export default useExtract;
