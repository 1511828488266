import { useEffect, useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { FieldValues } from '../../@types/RestField';
import { checkEmptyObj } from '../../helper/checkEmptyObj';
import { NoDataContainer } from './styles';

export interface INoDataMessage {
  message: string;
  data: FieldValues;
}

export const NoData = ({ message, data }: INoDataMessage) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!checkEmptyObj(data)) {
        setShowMessage(true);
      }
    }, 9000);
  }, []);

  return (
    <NoDataContainer>
      <Loader content={ message } inline active={ !showMessage } />
      { showMessage && (
        <Message
          header='Ops!'
          content='Não foi possível carregar os dados, refaça o login e tente novamente!'
          color='red'
        />
      ) }
    </NoDataContainer>
  );
};
