import { IRestMsg } from "../interfaces/IAuth";

const REST = {
  HML: "https://apihml.ethosx.com.br:7850/rest",
  PROD: "http://protheus.coopex.coop.br:7810/rest",
};

const PREFIX = REST.PROD;

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

async function request<T>(endpoint: string, config?: RequestInit): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(`${PREFIX}/${endpoint}`, {
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "pt-BR",
    },
    ...config,
  }).catch((error) => error);

  try {
    response.parsedBody = await response.json();
  } catch (error) {
    throw new Error(response.statusText);
  }

  if (!response.ok) {
    if (response.status === 400) {
      const error = response.parsedBody as IRestMsg;
      throw error.errorMessage;
    }
    throw new Error(response.statusText);
  }

  return response;
}

async function get<T>(endpoint: string, config?: RequestInit): Promise<HttpResponse<T>> {
  return await request<T>(endpoint, { method: "GET", ...config });
}

async function post<T>(endpoint: string, config?: RequestInit): Promise<HttpResponse<T>> {
  return await request<T>(endpoint, { method: "POST", ...config });
}

async function put<T>(endpoint: string, config?: RequestInit): Promise<HttpResponse<T>> {
  return await request<T>(endpoint, { method: "PUT", ...config, cache: "reload", mode: "cors" });
}

export default { get, post, put };
