import React, { useContext, FC, createContext, useState } from 'react';
import { TProps } from '../@types/types';

export interface IMenuContext {
  selectedMenuItem: string;
  setSelectedMenuItem(name: string): void;
}

export const MenuContext = createContext<IMenuContext>({} as IMenuContext);

const MenuContextProvider: FC<TProps> = ({ children }: TProps) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('home');

  const updateSelectedMenuItem = (name: string) => setSelectedMenuItem(name);

  return (
    <MenuContext.Provider
      value={ {
        selectedMenuItem,
        setSelectedMenuItem: updateSelectedMenuItem,
      } }
    >
      { children }
    </MenuContext.Provider>
  );
};

export default MenuContextProvider;

export function useMenuContext(): IMenuContext {
  const context = useContext(MenuContext);
  if (!context)
    throw new Error('useMenuContext deve ser usado em um MenuContextProvider');
  return context;
}
