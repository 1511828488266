import styled from "styled-components";

export const FlashMainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .flashContent {
    color: black;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font: 300 1.2rem ${({ theme }) => theme.fonts.mainFont} !important;
  }

  .flashColor {
    color: ${({ theme }) => theme.colors.flashColor};
  }
`;
