import React, { useEffect, useState } from 'react';
import { FormHandler } from '../../@types/types';
import { useLocation, useNavigate } from 'react-router-dom';
import data from '../../../package.json';
import Swal from 'sweetalert2';
import { encode } from 'js-base64';

// STYLES
import {
  MainContainer,
  LoginContainer,
  Bg,
  LogoContainer,
} from '../Login/styles';

//SERVICES
import { Auth } from '../../services/auth.service';

// IMAGENS
import logo from '../../assets/images/logo.png';

// COMPONENTS
import {
  Input,
  Form,
  Button,
  Image,
  Icon,
  Popup,
  Card,
  List,
} from 'semantic-ui-react';
import { ErrorModal } from '../../components/MessageModal';
import { validNewPassword } from '../../helper/checkFields';
import { INewPass } from '../../interfaces/IAuth';


export function ChangePass() {
  const auth = new Auth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) navigate('/');
  }, []);


  const [password, setPassword] = useState('');
  const [newpass, setNewPass] = useState('');
  const [msg, setMsgFromServer] = useState('');
  const [buttonState, setButtonState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passRequirement, setPassRequirement] = useState<INewPass>({} as INewPass)

  async function handleSubmit(event: FormHandler) {
    event.preventDefault();
    setMsgFromServer('');
    setLoading(true);

    try {

      const { status, parsedBody } = await auth.useChangePassword(encode(newpass));

      if ((status === 200 || status === 201) && parsedBody) {
        if (parsedBody?.status === 'erro') {
          setLoading(false);
          setMsgFromServer(parsedBody.msg);
          setOpenModal(true);
          return;
        }

        Swal.fire("", parsedBody?.msg, 'success').then(() => {
          setMsgFromServer('');
          navigate('/login');
        })
      }


    } catch (error) {
      console.log(error);
      setOpenModal(true);
      setMsgFromServer('Ops, algo deu errado !');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    function validate() {
      const result = validNewPassword(password, newpass);
      const passwordIsValid = Object.values(result).every((value) => value);
      setPassRequirement(prev => ({ ...prev, ...result }));

      if (passwordIsValid) {
        setButtonState(false);
        return;
      }
      setButtonState(true);
    }
    validate();
  }, [password, newpass]);

  return (
    <MainContainer>
      <ErrorModal
        title='Mensagem'
        message={ msg }
        visible={ openModal }
        onClose={ () => setOpenModal(false) }
      />

      <Form onSubmit={ handleSubmit } error>
        <LoginContainer>
          <Bg />
          <LogoContainer>
            <Image src={ logo } className='logotipo' />
            <p className='text_cooperativa'>PORTAL DO COOPERADO</p>
          </LogoContainer>

          <h1>Cadastrar nova senha </h1>
          <Form.Field>
            <Input
              type={ showPassword ? 'text' : 'password' }
              icon={ { name: 'lock', className: 'icon' } }
              iconPosition='left'
              focus
              size='big'
              placeholder='Nova senha'
              value={ password }
              onChange={ ({ target }) => setPassword(target.value) }
              autoComplete='password'
              className='inputLogin'
            />
          </Form.Field>
          <Form.Field>
            <Input
              type={ showPassword ? 'text' : 'password' }
              icon={ { name: 'lock', className: 'icon' } }
              size='big'
              focus
              iconPosition='left'
              placeholder='Repita a nova senha'
              value={ newpass }
              onChange={ ({ target }) => setNewPass(target.value) }
              autoComplete='password'
              className='inputLogin'
            />
            { showPassword ? (
              <Icon
                name='eye'
                className='eyePassword'
                size='large'
                onClick={ () => setShowPassword(!showPassword) }
              />
            ) : (
              <Icon
                name='eye slash'
                className='eyePassword'
                size='large'
                onClick={ () => setShowPassword(!showPassword) }
              />
            ) }

          </Form.Field>
          <Popup
            trigger={ <Icon name='help circle' size='large' color={ 'red' } /> }
            position='top center'

          >
            <Popup.Header>Requisitos da senha</Popup.Header>
            <Popup.Content>
              <Card>
                <Card.Content>
                  <Card.Description>
                    <List>

                      <List.Item>
                        <List.Icon name={
                          passRequirement.haslowercase && passRequirement.hasuppercase
                            ? 'check'
                            : 'close'
                        }
                          color={
                            passRequirement.haslowercase && passRequirement.hasuppercase
                              ? 'green'
                              : 'red'
                          } />
                        <List.Content>
                          Letra Minúscula e Maiúscula
                        </List.Content>
                      </List.Item>


                      <List.Item>
                        <List.Icon
                          name={
                            passRequirement.hasminimumSize
                              ? 'check'
                              : 'close'
                          }
                          color={ passRequirement.hasminimumSize ? 'green' : 'red' }
                        />
                        <List.Content>
                          Conter ao menos 5 caracteres
                        </List.Content>
                      </List.Item>


                      <List.Item>
                        <List.Icon
                          name={
                            passRequirement.hasnumber
                              ? 'check'
                              : 'close'
                          }
                          color={ passRequirement.hasnumber ? 'green' : 'red' }
                        />
                        <List.Content>
                          Conter ao menos 1 valor númerico.
                        </List.Content>
                      </List.Item>


                      <List.Item>
                        <List.Icon
                          name={
                            passRequirement.hasspecialchar
                              ? 'check'
                              : 'close'
                          }
                          color={ passRequirement.hasspecialchar ? 'green' : 'red' }
                        />
                        <List.Content>
                          Conter ao menos 1 caracter especial.
                        </List.Content>
                      </List.Item>


                      <List.Item>
                        <List.Icon
                          name={
                            passRequirement.samepass
                              ? 'check'
                              : 'close'
                          }
                          color={ passRequirement.samepass ? 'green' : 'red' }
                        />
                        <List.Content>
                          Senhas são iguais
                        </List.Content>
                      </List.Item>


                    </List>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Popup.Content>
          </Popup>

          <Button
            content='ENVIAR'
            type='submit'
            id='button-login'
            disabled={ buttonState }
            loading={ loading }
          />

          <p className='version'>{ `v.${ data.version }-stable` }</p>
        </LoginContainer>
      </Form>
    </MainContainer>
  );
}
