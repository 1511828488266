export enum EnumItemsMenu {
  home = 'home',
  benefits = 'star',
  extratos = 'paste',
  manual = 'address book',
  info = 'info circle',
}

export interface IMenuProps {
  name: string;
  icon: EnumItemsMenu;
}

export const MenuItems: IMenuProps[] = [
  {
    name: 'Home',
    icon: EnumItemsMenu.home,
  },
  {
    name: 'Beneficios',
    icon: EnumItemsMenu.benefits,
  },
  {
    name: 'Extratos',
    icon: EnumItemsMenu.extratos,
  },
  {
    name: 'Manual',
    icon: EnumItemsMenu.manual,
  },
];
