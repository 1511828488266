import styled from 'styled-components';

export const ContainerUserProfile = styled.div`
  flex-direction: column;
  display: flex;
  width: 90%;
  
  .ui.tabular.menu .item {
    font-weight: 600;    
    transition: ease 0.4s, transform 10s;
  }

  .ui.tabular.menu .active.item {    
    color:  ${({ theme }) => theme.colors.primary} !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  .ui.label {
    font: 1.2rem ${({ theme }) => theme.fonts.mainFont} !important; 
  }

  .ui.input>input {
    font: 400 1.2rem ${({ theme }) => theme.fonts.mainFont} !important;
    color: black;
    opacity: 1;
  }

  .ui.breadcrumb .active.section {
    font: 1.3rem ${({ theme }) => theme.fonts.mainFont} !important; 
    color:  ${({ theme }) => theme.colors.secondary} !important;   
  }

  .activeRouteLink::first-letter {
    text-transform: uppercase;
  }

  .titleProfile {
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary} !important;
    justify-content: center;
    display: flex;
    margin-top: 1rem !important;
  }

  .formUserData {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .label {
    font-size: 1.2rem !important;
    margin-top: 0.5rem !important;
    width: 40% !important;
  }

  .fieldInput {
    width: 50% !important;
  }

  .btnSave {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    height: 44px;
    width: 120px;
    margin-top: 1rem;
    align-self: flex-end;
    visibility: hidden;
  }

  .btnSave:hover {
    background-color: ${({ theme }) => theme.colors.success};
    color: white;
  }
`;
