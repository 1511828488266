import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// COMPONENTS
import { Breadcrumb, Button, Header, Icon, InputProps, Tab } from 'semantic-ui-react';
import { UserTab } from '../../components/UserTab';
import { NoData } from '../../components/NoData';
import Layout from '../../components/Layout';
import { EnumItemsMenu } from '../../data/MenuItems';

//SERVICE
import updateUserData from '../../services/updateUserData.service';
import {
  setDataUser,
  UserReducerInitialState,
} from '../../redux/reducers/User';

//STYLE
import { ContainerUserProfile } from './styles';

//CONTEXT
import { useMenuContext } from '../../contexts/MenuContext';

//INTERFACES
import { IDataUser } from '../../interfaces/IUser';

//HELPER
import { checkEmptyObj } from '../../helper/checkEmptyObj';

export function Profile() {

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [msgFromServer, setMsgFromServer] = useState('');
  const [prevRoute, setPrevRoute] = useState<string>('home');
  const { setSelectedMenuItem } = useMenuContext();

  useEffect(() => {
    if (location.state) {
      setPrevRoute(location.state.replace("/", ""))
    }
  }, [])


  const { dataUser } = useSelector(
    (state: { user: UserReducerInitialState }) => state.user
  );

  const dispatch = useDispatch();

  interface ITabProps {
    menuItem: string;
    render: () => ReactNode;
  }

  useEffect(() => {
    // Informa se o icone do menu deve mudar de cor, para alterar, informar o nome da rota
    setSelectedMenuItem('');
  });

  const buildPanes = () => {
    const panes: ITabProps[] = [];

    const fields = Object.entries(dataUser).sort().reverse();

    fields.forEach((key) => {
      const tab = key[0];
      const data = Object.entries(key[1]).sort();

      panes.push({
        menuItem: `${ tab[0].toUpperCase() }${ tab.slice(1) }`,
        render: () => UserTab(tab, data, loading, onChangeData),
      });
    });

    return panes;
  };

  const TabsMenu = () => <Tab panes={ buildPanes() } />;

  // ATUALIZA OS DADOS DO USUSÁRIO, AINDA NÃO IMPLEMENTADO
  const updateData = async () => {
    try {
      setLoading(true);
      const result = await updateUserData(dataUser);

      if (result.status === '500') {
        console.log('erro');
      }
    } catch (error: any) {
      setMsgFromServer(error);
      console.log(error);

      setTimeout(() => {
        setLoading(false);
        setMsgFromServer('');
      }, 4000);
    } finally {
      setLoading(false);
    }
  };

  const onChangeData = (e: unknown, { tab, name, value }: InputProps) => {
    if (name === 'Nome' || name === 'Matricula') return;

    // Copia dos dados do usuario
    const copyDataUser: IDataUser = { ...dataUser };

    // refaz a chave com o novo valor
    const newValue = {
      [tab]: { ...copyDataUser[tab], [name]: value },
    };

    dispatch(setDataUser({ value: newValue }));
  };

  return (
    <Layout>
      <ContainerUserProfile>

        <Breadcrumb>
          <Breadcrumb.Section link onClick={ () => navigate(-1) }>{ prevRoute }</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active className='activeRouteLink'>{ location.pathname.replace("/", "") }</Breadcrumb.Section>
        </Breadcrumb>

        <Header as='h4' size='small' className='titleProfile'>
          <Icon name={ EnumItemsMenu.info } />
          Dados Pessoais
        </Header>
        { checkEmptyObj(dataUser) ? (
          TabsMenu()
        ) : (
          <NoData message='Carregando os dados, aguarde...' data={ dataUser } />
        ) }
        <Button
          type='button'
          className='btnSave'
          content='SALVAR'
          onClick={ () => updateData() }
          loading={ loading }
        />
      </ContainerUserProfile>
    </Layout>
  );
}
