import React, { FC } from 'react';
import { TProps } from '../../@types/types';

import Footer from '../Footer';
import { DefaultHeader } from '../Header';
import MainMenu from '../Menu';
import { WhatsModule } from '../WhatsApp';
import {
  BrowserContent,
  ContainerDivider,
  ContainerDividerFooter,
  Content,
  PageDefault,
} from './styles';

const Layout: FC<TProps> = ({ children }: TProps) => {
  return (
    <PageDefault>
      <DefaultHeader />
      <MainMenu />
      <Content>
        <BrowserContent>
          <ContainerDivider />
          { children }
          <ContainerDividerFooter />
          <Footer />
        </BrowserContent>
      </Content>

      <WhatsModule />
    </PageDefault >
  );
};

export default Layout;
