import styled from "styled-components";

export const FiapMainContainer = styled.div`
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .gympassContent {
    color: black;
    display: flex;
    font: 300 1.2rem ${({ theme }) => theme.fonts.mainFont} !important;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 1rem;
  }

  .gympassColor {
    color: ${({ theme }) => theme.colors.gympassColor};
  }
`;
