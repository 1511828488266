import styled from 'styled-components';

export const ManualContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 2rem;

  .link {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.secondary};
  }

  .grid {
    border: none !important;
  }
`;
