import CryptoJS, { enc } from 'crypto-js';

export const encrypt = (text: string) => {
  return CryptoJS.AES.encrypt(
    text,
    process.env.REACT_APP_SECRET_PHRASE as string
  ).toString();
};

export const descrypt = (itemEncrypt: string) => {
  if (itemEncrypt) {
    const response = CryptoJS.AES.decrypt(
      itemEncrypt,
      process.env.REACT_APP_SECRET_PHRASE as string
    );
    return response.toString(enc.Utf8);
  }

  return '';
};
