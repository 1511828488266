import styled from "styled-components";
import background from "../../assets/images/login/background.jpeg";

export const MainContainer = styled.div`
  align-items: center;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  font-weight: 700;
  width: 100vw;
  height: 100vh;
  justify-content: center;
`;

export const Logotipo = styled.img`
  width: 120px;
  max-width: 100%;
`;

export const LoginContainer = styled.div`
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 50px;
  width: 90%;
  margin: auto;
  position: relative;
  z-index: 1;

  h1 {
    color: ${({ theme }) => theme.colors.secondary} !important;
    margin-top: -0.2rem;
  }

  .inputLogin {
    height: 3.2rem;
    width: 310px !important;
  }

  .logotipo {
    width: 120px;
    height: 120px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .icon {
    color: ${({ theme }) => theme.colors.primary};
  }

  .eyePassword {
    position: absolute;
    z-index: 9999;
    right: 3.2rem;
    margin-top: 0.9rem;
    cursor: pointer;
  }

  #button-login {
    background-color: ${({ theme }) => theme.colors.primary};
    height: 3rem;
    margin-top: 2rem;
    width: 310px;
    margin-bottom: 3rem;
    color: white;
    border-radius: 10px;
  }

  .version {
    position: absolute;
    bottom: 0;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .text_cooperativa {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 9px;
    font-weight: bold;
    margin-bottom: 3rem;
    margin-top: -3.7rem;
  }
`;

export const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
  z-index: -1;
`;

export const CheckBoxContainer = styled.div`
  border-radius: 10px;
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;

  .forgot_password,
  .remember_me {
    background-color: transparent;
    font-weight: 100;
    cursor: pointer;
  }

  .forgot_password:hover {
    color: black;
    font-weight: 400;
  }
`;
