import styled from "styled-components";

export const ReportContainer = styled.div`
  width: 80% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  margin-top: 2rem;

  .titleReport {
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary};
    justify-content: center;
    display: flex;
    margin-top: 1rem !important;
  }

  .select {
    margin-bottom: 1.5rem;
    font: 400 1.5rem ${({ theme }) => theme.fonts.mainFont};
  }

  .btnConfirm {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    height: 50px;
    font-size: 18px;
  }

  .btnConfirm:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
  }
`;
